<template>
  <v-container>
    <v-form :value="formValid" ref="form">
      <v-card rounded :disabled="showDialog">
        <v-card-text>
          <v-row>
            <v-col>
              <div class="headline">{{$t('MSG_LEAVE_TYPE')}}</div>
              <custom-select
                  v-model="selectedLeaveType"
                  :items="leaveTypes"
                  :label="$t('LBL_SELECT')"
              />
            </v-col>
          </v-row>
          <v-date-picker
              landscape no-title
              :min="minAllowedDate()"
              v-model="dates"
              :locale="$i18n.locale"
              :rules="validator.date"
              range show-current show-adjacent-months full-width
          ></v-date-picker>
          <v-row>
            <v-col>
              <v-text-field
                  readonly
                  :value="sortedDates[0]"
                  :rules="validator.date"
                  :prefix="$t('LBL_LEAVE_START')"
              />
            </v-col>
            <v-col>
              <v-text-field
                  readonly
                  :value="sortedDates.length>1 ? sortedDates[1] : sortedDates[0]"
                  :prefix="$t('LBL_LEAVE_END')"
              />
            </v-col>
            <v-col>
              <v-text-field readonly :value="dateRangeText()"/>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <custom-dialog
              v-model="showDialog"
              :activator-label="$t('LBL_APPLY_LEAVE')"
              :dialog-title="$t('MSG_CONFIRM_LEAVE')"
              @activate="onSubmit"
              @cancel="onCancel"
              @confirm="onConfirm"
          />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import CustomDialog from '@/components/CustomDialog';
import CustomSelect from '@/components/CustomSelect';
import router from '@/router';

export default {
  name: "Leave",
  $ref: {
    form: HTMLFormElement,
  },
  components: {CustomSelect, CustomDialog},
  data: () => ({
    formValid: false,
    showDialog: false,
    dates: [],
    selectedLeaveType: {},
    leaveTypes: [],
    validator: {}
  }),
  computed: {
    sortedDates() {
      return [...this.dates].sort();
    },
  },
  mounted() {
    this.validator = {
      date: [(v) => !!v && v.length > 0 || this.$t('MSG_DATE_RANGE')]
    };
    this.leaveTypes = [
      { text: this.$t("type.leave.sick"), value: "s" },
      { text: this.$t("type.leave.annual"), value: "a" },
      { text: this.$t("type.leave.compassion"), value: "c" },
      { text: this.$t("type.leave.nopay"), value: "n" },
      { text: this.$t("type.leave.compensate"), value: "p" },
      { text: this.$t("type.leave.others"), value: "o" },
    ];
  },
  methods: {
    minAllowedDate() {
      const utcDate = new Date(); // This is from UTC+0, need to change to local date time.
      const localDate = new Date(utcDate.getTime() - (utcDate.getTimezoneOffset()*60*1000))
      return localDate.toISOString().split("T")[0];
    },
    dateRangeText () {
      let _dates = [...this.dates].sort();

      const rangeText =
          _dates.length === 0
          ? 'No date selected'
          : _dates.join(' ~ ');

      let days = this.dates.length;
      if(this.dates.length > 1) {
        let startTime = Date.parse(_dates[0]);
        let endTime = Date.parse(_dates[1]);
        let diff = Math.abs(endTime - startTime);
        days = diff/(1000*60*60*24)+1;
      }

      return this.$t('LEAVE_RANGE', {
        range: rangeText,
        num: days,
        days: this.$tc('DAYS', days)
      });
    },
    onCancel() {
      this.showDialog = false;
    },
    async onConfirm() {
      if(this.dates.length === 1)
        this.dates.push(this.dates[0]);

      const result = await this.$store.dispatch('applyLeave', {
        type: this.selectedLeaveType.value,
        dates: this.dates,
      });

      if (result) {
        await router.replace('/leave/view');
      }
    },
    onSubmit() {
      if (this.$refs.form.validate()) {
        this.showDialog = true;
      }
    },
  }
}
</script>
<i18n>
{
  "en": {
    "DAYS": "day | days",
    "LBL_LEAVE_START": "Start:",
    "LBL_LEAVE_END": "End:",
    "LEAVE_RANGE": "Total {num} {days}.",
    "LBL_SELECT": "Select Leave Type",
    "MSG_LEAVE_TYPE": "Type of leave:",
    "LBL_APPLY_LEAVE": "Apply Leave",
    "MSG_DATE_RANGE": "Please select at least one day",
    "MSG_CONFIRM_LEAVE": "Confirm to apply leave?"
  }
}
</i18n>
