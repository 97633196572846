<template>
  <v-container>
    <v-card rounded>
      <v-card-title>{{$t('title')}}</v-card-title>
      <v-card-subtitle>{{$t('subtitle', {type: getLeaveTypeName(leave.type)})}}</v-card-subtitle>
      <v-card-text v-if="leave">{{$t('text', {start: formatDate(leave.start), end: formatDate(leave.end)})}}</v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "ViewLeave",
  computed: {
    ...mapGetters(['getCurrentLeave']),
  },
  methods: {
    formatDate(date) {
      if(!date) return '';
      return this.$d(new Date(date).getTime());
    },
    getLeaveTypeName(type) {
      switch (type) {
        case "s": return this.$t("type.leave.sick");
        case "a": return this.$t("type.leave.annual");
        case "c": return this.$t("type.leave.compassion");
        case "n": return this.$t("type.leave.nopay");
        case "p": return this.$t("type.leave.compensate");
        case "o": return this.$t("type.leave.others");
      }
    }
  },
  data: () => ({
    leave: {},
  }),
  mounted() {
    this.leave = this.getCurrentLeave;
  }
}
</script>
<i18n>
{
  "en": {
    "title": "You are on leave.",
    "subtitle": "Type: {type}",
    "text": "Duration: {start} ~ {end}"
  }
}
</i18n>
